import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../components/Layout";
import CreateFirstEventButton from "../components/CreateFirstEventButton";
import PublicEventsList from "../components/PublicEventsList";
import { navigate } from 'gatsby';
export const _frontmatter = {
  "title": "Mindful Events"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Conscious Event - Lagos`}</h1>
    <PublicEventsList mdxType="PublicEventsList" />
    <p>{`Listing your own events? `}<a parentName="p" {...{
        "href": "/owned-events"
      }}>{`Manage your events`}</a>{`.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      